
<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />

</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_dtta',
      headers: [
        [{
          label: 'Nama Lengkap Dosen Tamu dan Tenaga Ahli',
          dataKey: 'data_master_dosen.full_name'
        }, {
          label: 'Nama Lembaga',
          dataKey: 'nama_lembaga',
        }, {
          label: 'Kepakaran',
          dataKey: 'kepakaran',
        }, {
          label: 'Nama Mata Kuliah',
          dataKey: 'mata_kuliah',
        }, {
          label: 'Waktu Kegiatan',
          dataKey: 'waktu_kegiatan',
        }, {
          label: 'Bukti Kegiatan',
          dataKey: 'bukti_kegiatan',
        }]
      ],
      form: {
        inputs: [{
          label: 'Nama Dosen',
          name: 'data_master_dosen_id',
          async dataKey(data, input) {
            const response = await useJwt.http.get(`data_master_dosen/${data.data_master_dosen_id}?state=all`)
            if (response.data.data_master_dosens) {
              const { id: value, full_name: label } = response.data.data_master_dosens
              input.options = [{ label, value }]
              return value
            }
            return 0
          },
          type: 'select',
          options: [],
          placeholder: 'Cari nama dosen',
          rules: 'required',
          async fetchOptions(search, loading) {
            if (search.length >= 3) {
              loading(true)
              const response = await useJwt.http.get('data_master_dosen', { params: { full_name: search } })
              const fetchedOptions = response.data.data_master_dosens.map(dosen => ({ label: dosen.full_name, value: dosen.id }))
              loading(false)
              return fetchedOptions
            }
          },
          customSelectedValue(data) {
            return data.value
          },
        }, {
          label: 'Nama Lembaga',
          name: 'nama_lembaga',
          dataKey: 'nama_lembaga',
          type: 'textarea',
          
        }, {
          label: 'Kepakaran',
          name: 'kepakaran',
          dataKey: 'kepakaran',
          type: 'textarea',
          
        }, {
          label: 'Nama Mata Kuliah',
          name: 'matkul_name',
          dataKey: 'matkul.name',
          type: 'select',
          options: [],
          placeholder: 'Cari mata kuliah',
          
          col: 6,
          async fetchOptions(search) {
            if (search.length >= 3) {
              const response = await useJwt.http.get('matkul', { params: { full_name: search } })
              let fetchedOptions
              if (response.data.matkuls.length) {
                fetchedOptions = response.data.matkuls.map(matkul => ({ label: matkul.name, value: matkul.code_mk }))
              } else {
                fetchedOptions = [{ label: `${search} (buat data matkul baru)`, value: '' }]
              }
              return fetchedOptions
            }
          },
          customSelectedValue(data, formData) {
            formData.create.code_mk = data.value
            formData.edit.code_mk = data.value
            return (data.label || '').replace(' (buat data matkul baru)', '')
          },
        }, {
          label: 'Kode Mata Kuliah',
          name: 'code_mk',
          dataKey: 'matkul.code_mk',
          type: 'text',
          
          col: 6,
        }, {
          label: 'Waktu Kegiatan',
          name: 'waktu_kegiatan',
          dataKey(data) {
            return moment(data.waktu_kegiatan, 'MMMM YYYYY').format('MMMM YYYY')
          },
          type: 'date',
          
          defaultValue: moment().format('MMMM YYYY'),
          flatPickrConfig: {
            dateFormat: 'F Y',
            altFormat: 'F Y',
            ariaDateFormat: 'F Y',
          }
        }, {
          label: 'Bukti Kegiatan',
          name: 'bukti_kegiatan',
          dataKey: 'bukti_kegiatan',
          type: 'textarea',
          
        }]
      },
    }
  },
}
</script>
